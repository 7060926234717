import { useEffect, useState } from 'react'

import { VWO_GOAL_EXPERIMENT } from 'gatsby-env-variables'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { goalDictionary } from '../../../constants'
import services from './services'

const useSelectGoal = () => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)
  const { setIsLoading, loading } = useProposal()
  const { changeLeadData, leadData } = useLead()

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'formulario-objetivo',
      customLayer: {
        testName: VWO_GOAL_EXPERIMENT,
        testVariant: leadData?.vwoVariant
      }
    })
  }, [])

  useEffect(() => {
    const getData = async () => {
      const { previous_step } = await services.getGoal()
      changeLeadData({
        previousStep: previous_step
      })
      setGetLoading(false)
    }

    getData()
    console.log('goal mounted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading])

  const onSubmit = async (payload) => {
    setSubmitLoading(true)

    const { goal } = payload

    analytics.track('buttonClick', {
      currentPage: 'formulario-objetivo',
      detail: 'click-step-objetivo',
      customLayer: {
        description: `objetivo-${goalDictionary[goal]}`
      }
    })

    if (goal === 'others') {
      changeLeadData({
        previousStep: 'select-macro-goal'
      })
      goTo('select-goal-customize')
    } else {
      const requestBody = { goal }

      return services
        .putGoal({ requestBody })
        .then(({ next_step }) => {
          changeLeadData(requestBody)
          goTo(next_step)
        })
        .finally(() => setSubmitLoading(false))
    }
  }

  return { onSubmit, loading, submitLoading, getLoading }
}

export default useSelectGoal

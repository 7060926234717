import { useForm } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { IdsRadioButton } from '@ids/react'
import {
  Navigation,
  If,
  DsIcon as Icon
} from '@itau-loans-www/shopping/src/components'
import ProposalLayout from 'layouts/Proposal'

import Shimmer from 'components/Shimmer'

import { goals } from '../../../constants'
import useSelectGoal from './hooks'
import './select-goal.scss'

const SelectGoal = ({ pageContext }) => {
  const initialValues = { goal: '' }
  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { field } = useController({
    name: 'goal',
    control
  })

  const { onSubmit, submitLoading, getLoading } = useSelectGoal()
  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={getLoading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 20px 0"
              widthDesktop="65%"
              height="64px"
              heightDesktop="40px"
            />
            <Shimmer margin="10px 0 20px 0" height="48px" />
            <Shimmer height="48px" />
            <Shimmer margin="20px 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer margin="0 0 30px 0" height="86px" />
            <Shimmer margin="30px 0 0 0" widthDesktop="50%" height="50px" />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title">
              Conta pra gente qual é o seu objetivo?
            </h1>
            <form
              className="form select-goal"
              onSubmit={handleSubmit(onSubmit)}
            >
              {goals.map(({ label, id, icon, value }) => {
                return (
                  <IdsRadioButton
                    {...field}
                    key={id}
                    value={value}
                    name="noSelection"
                    onChange={(e) => field.onChange(e)}
                  >
                    <Icon name={icon?.name} type={icon?.type} />
                    {label}
                  </IdsRadioButton>
                )
              })}

              <span className="select-goal__disclaimer">
                As respostas serão utilizadas para montar a
                melhor proposta para você e não afetarão o valor e as taxas de
                sua proposta.
              </span>

              <Navigation
                submit
                nextStep
                isNextButtonDisabled={!field.value}
                isNextButtonLoading={submitLoading}
                nextButtonId="select-goal-next-btn"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default SelectGoal
